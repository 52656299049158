.btn {
  // text-transform: uppercase;
  line-height: 1;
  transition: $transition;
  font-weight: 500;
  &-gradient {
    background: transparent linear-gradient(90deg, #e9bf57 0%, #5c430b 100%) 0%
      0% no-repeat padding-box;
    color: $white;
    &:hover {
      color: $white;
      opacity: 0.75;
    }
    &-border {
      position: relative;
      background: transparent linear-gradient(90deg, #e9bf57 0%, #5c430b 100%)
        0% 0% no-repeat padding-box;
      color: $black;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: $border-radius-pill;
        background-color: $white;
        transition: $transition;
      }
      span {
        position: relative;
        z-index: 1;
      }
      &:hover {
        color: $white;
        &:before {
          opacity: 0;
        }
      }
    }
  }
  &-theme {
    background: linear-gradient(190deg, $primary 0%, $secondary 100%);
    box-shadow: 0px 18px 60px rgba(252, 0, 255, 0.18);
    border-radius: 48px;
    border: none;
    color: $white;
    font-size: 16px;
    line-height: 16px;
    padding: 19px 40px;
    &:hover {
      color: $white;
      opacity: 0.75;
    }
  }
}

.extra-padding {
  padding: 40px;
}

ol,
ul {
  padding-left: 1rem;
}
