@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@import 'fonts';
@import 'variables';

/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */

@import 'responsive';
@import 'utilities';
@import 'reboot';
@import 'all';
@import "../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

