// colors
$primary:                       #1F1F1F !default;
$secondary:                     #FFC700 !default;
$tertiary:                      #161616 !default;

$danger:                        #8f190f !default;
$warning:                       #be182f !default;
$success:                       #2dac28 !default;

$black:                         #000000 !default;
$white:                         #FFFFFF !default;
$light:                         #EAEAEA !default;
$grey:                          #393939 !default;
$light-grey:                    #ABABAB!default;


// theme colorsrgba(0, 0, 0, 0.60)
$theme-colors: (
    "primary":      $primary,
    "secondary":    $secondary,
    "tertiary":     $tertiary,

    "danger":       $danger,
    "warning":      $warning,
    "success":      $success,

    "black":        $black,
    "white":        $white,
    "light":        $light,
    "grey":         $grey,
    "light-grey":   $light-grey,

) !default;


// font size
$h1-font-size:                  2.5rem !default;
$h2-font-size:                  2rem !default;
$h3-font-size:                  1.75rem !default;
$h4-font-size:                  1.25rem !default;
$h5-font-size:                  1.125rem !default;
$h6-font-size:                  1rem !default;
$font-size-sm:                  0.875rem !default;
$font-size-xs:                  0.75rem !default;
$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    7: $font-size-sm,
    8: $font-size-xs,
) !default;

// font weight
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;

// spacing
$spacer:                        1rem;
$headerHt:                      125px;

// container width
$container-max-width:           1440px;

// border radius
$border-radius:                 $spacer * 0.3 !default;
$border-radius-sm:              $spacer * 0.4 !default;
$border-radius-lg:              $spacer * 0.5!default;
$border-radius-xl:              $spacer !default;
$border-radius-pill:            $spacer * 50 !default;

// Fonts
$font-family-primary:          'Ubuntu', sans-serif !default;
$font-family-secondary:        'Tokyo 2097', sans-serif !default;
$font-family-tertiary:         'Lexend Deca', sans-serif !default;


$font-size-base:                1rem !default;
$font-size-sm:                  0.875rem !default;
$font-size-lg:                  1.125rem !default;
$font-size-xl:                  1.5rem !default;
$line-height-base:              1.4;
$font-weight-base:              $font-weight-regular !default;

// transition
$transition:                    all 0.25s ease-in-out;

// breakpoints
$grid-breakpoints: (
  xxs:                        0,
  mini:                       300px,
  min:                        360px,
  xs:                         410px,
  sm:                         576px,
  md:                         768px,
  lg:                         1025px,
  xl:                         1200px,
  xxl:                        1440px,
) !default;

// Forms
$input-padding-y:               $spacer * 0.875 !default;
$input-padding-x:               $spacer !default;
$input-font-family:             $font-family-primary !default;
$input-font-size:               $h5-font-size !default;
$input-line-height:             1.2 !default;
$input-disabled-bg:             $grey !default;
$input-focus-bg:                $grey !default;
$input-focus-border-color:      $grey !default;
$input-focus-box-shadow:        none !default;
$input-color:                   $white !default;
$input-focus-color:             $input-color !default;
$input-border-width:            1px !default;
$input-box-shadow:              none !default;
$input-placeholder-color:       $grey !default;
$input-plaintext-color:         $secondary !default;
$input-height-border:           1px !default;
$input-height-inner:            add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:       add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:    add($input-line-height * .25em, $input-padding-y * .5) !default;
$input-height:                  add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-border-radius:           $border-radius-sm !default;


// btn
$btn-padding-y:                 $spacer * 0.75 !default;
$btn-padding-x:                 $spacer * 1 !default;
$btn-border-radius:             999px !default;
$btn-border-width:              2px !default;

// box shaadow
$box-shadow-xs: 0px 2px 5px rgba(72, 101, 129, 0.1) !default;
$box-shadow-sm: 0px 4px 10px rgba(71, 163, 243, 0.15), 0px 2px 11px rgba(163, 183, 236, 0.21) !default;
$box-shadow-md: 0px 4px 46px rgba(71, 163, 243, 0.15), 0px 2px 11px rgba(163, 183, 236, 0.21) !default;
$box-shadow-lg: 0px 4px 91px rgba(255, 139, 91, 0.15), 0px 2px 50px rgba(107, 31, 0, 0.06) !default;
$box-shadow-xl: 0px 8px 100px rgba(72, 101, 129, 0.15), 0px 4px 45px rgba(72, 101, 129, 0.08) !default;


// object style
$modal-backdrop-bg: $secondary;
$object-size-start: 5;
$object-size-end: 1000;
$object-sizes: "";
@for $i from $object-size-start + 5 through $object-size-end {
  $object-sizes: append($object-sizes, $i, comma);
  $object-sizes: set-nth($object-sizes, 1, $object-size-start);
}
