html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  color: $white;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0;
  background: $secondary;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($primary, 0.7);
  // border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba($primary, 0.6);
}

/* Handle on press */
::-webkit-scrollbar-thumb:active {
  background: rgba($primary, 0.8);
}

:focus {
  outline: none;
}

a {
  transition: $transition;
  text-decoration: none;
  color: $white;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none !important;
  }
}

.container {
  max-width: 1250px;
}

// ===== space between sections style=========

.section-title {
  text-align: center;
  font-family: $font-family-secondary;
  font-weight: $font-weight-bold;
  color: $white;
  font-size: 40px;
  text-transform: capitalize;
  display: inline-block;
  text-align: center;
  padding: 5px 14px;
  position: relative;
  z-index: 0;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $black;
    z-index: -1;
    transform: skewX(-10deg);
    @include lg-down {
      display: none;
    }
  }
  @include sm-down {
    font-size: 32px;
   
  }
}
.section-desc {
  text-align: center;
  color: $light-grey;
  font-size: 16px;
}

.section-line {
  max-width: 165px;
  height: 3px;
  background: $primary;
  margin: 0 auto;
  display: block;
}

.section-gap {
  padding: 80px 0;

  @include sm-down {
    padding: 60px 5px;
  }
}

// ===== Button style=========

.btn {
  font-family: $font-family-primary;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0;
  border: none;
  padding: 12px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;

  @include sm-down {
    font-size: 14px;
    min-width: 120px;
  }
}

.btn-sm {
  min-width: 120px;
}


.btn-primary {
  color: $white;
  letter-spacing: 0.5px;
  min-width: 140px;
  font-weight: 400!important;
  border-radius: 0px; 

  @include sm-down {
    font-size: 13px !important;
    padding: 12px 10px;
  }

  &:hover {
    background: rgba($primary, 0.9);
  }

  // svg {
  //   margin-right: 10px;
  // }
}

.btn-outline-primary {
  background-color: $primary;
  border: 3px solid #FFC700;
  border-radius: 30px;
  padding:8px 24px;

  // &:hover,
  // &:active {
  //   color: $secondary;
  //   background-color: rgba($primary, 0.2);
  //   border-color: rgba($primary, 0.2);

  //   span {
  //     color: $secondary !important;
  //   }

  // }
}

// ==== sweet alert messagePopup styling ====

.swal2-popup {
  padding: $spacer * 1.5 !important;
  width: 25em !important;
  background: $tertiary;

  @media only screen and (max-width: 767px) {
    padding: $spacer !important;
  }

  #swal2-title {
    color: $white;
  }

  .swal2-html-container {
    color: $white;
    font-size: 1rem;
    line-height: 30px !important;
    font-weight: 400 !important;
  }

  .swal2-icon {
    margin: 0 auto;
  }

  .swal2-actions {
    column-gap: $spacer * 0.5;

    button {
      min-width: $spacer * 5;
      outline: none !important;
    }

    .swal2-confirm {
      background: $primary !important;
      border-radius: 30px !important;
      color: $white !important;

      &:hover,
      &:focus {
        background: rgba(209, 19, 19, 0.9) !important;
        transform: scale(1.03) !important;
        box-shadow: rgba(209, 19, 19, 0.5) 0px 0px 0px 4px !important;
      }
    }

    .swal2-cancel {
      background: $warning !important;
      color: white !important;
      border-radius: 30px !important;

      &:hover {
        background: rgba($warning, 0.7) !important;
      }
    }

    .swal2-deny {
      border-radius: 30px !important;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.logo-family {
  font-family: $font-family-primary;
}

.info-text {
  font-family: $font-family-primary;
  color: $primary;
}

.cursor-pointer {
  cursor: pointer !important;
}

.language-flag {
  max-width: 50px;
  // max-height: 24px;
}

.max-btn {
  background: $primary;
  padding: 14px 11px;
  border-radius: 30px;
  color: $white;
  text-transform: uppercase;
  box-shadow: 0px 2px 4px rgba(12, 93, 158, 0.15),
    0px 2px 6px rgba(39, 34, 36, 0.1);

  &:hover,
  &:active,
  &:focus {
    background: $primary !important;
    opacity: 0.9;
    color: $white;
  }
}

.extra-padding {
  padding: 30px !important;
}

// template headings
.heading {
  font-size: 48px;
  font-weight: 350;
  font-family: $font-family-secondary;
  color: $secondary;

  @include sm-down {
    font-size: 34px;
  }
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.loading {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

// modal
.modal-dialog {
  max-width: 400px;
  margin: 0 auto;

  .modal-content {
    background: $tertiary;
    border-radius: 10px;
    box-shadow: 0px 10px 10px 1px rgba(0, 0, 0, 0.3);
    transition: all 100ms ease-out 0s;
    overflow: hidden;

    .modal-header {
      padding: 12px 15px;
      justify-content: center;
      // background: rgba($secondary, 0.1);
      border: none !important;

      .modal-title {
        font-size: $h6-font-size;
        color: $white;
        font-weight: 500;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .modal-body {
      padding: 20px 15px;
      line-height: $spacer * 1.5;
      min-height: 250px;

      .btn-secondary {
        text-transform: capitalize;
        border: 1px solid $secondary;
        transition: 0.25s all ease-in-out;
        background-color: rgba($secondary, 0.6);

        span {
          width: 90%;
        }

        &:hover {
          background-color: rgba($primary, 0.7);
          color: $white;
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-primary;
      }
    }
  }
}

// --- shimmer loading ---
.shimmer {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: $primary
    linear-gradient(
      to right,
      rgba($primary, 0.2) 0%,
      rgba($secondary, 0.5) 50%,
      rgba($primary, 0.2) 75%
    );
  background-size: 1000px 100%;
  color: transparent !important;
  border-color: transparent !important;
  border-radius: 4px;

  &.nobg {
    background-size: 0 !important;
  }

  &.outline-title-white,
  &.outline-title-secondary,
  .outline-title-white,
  .outline-title-secondary {
    -moz-text-stroke-color: transparent;
    -webkit-text-stroke-color: transparent;
  }

  .mat-select-arrow-wrapper,
  span,
  &svg-icon,
  & img,
  svg-icon,
  img {
    opacity: 0;
  }

  &.light {
    background: yellow
      linear-gradient(to right, #e4e6df 8%, #d9d1d1 18%, #e4e6df 33%);
    background-size: 1200px 100%;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
}

.btn-outline-white:hover {
  background: transparent !important;
  border: 2px solid $white;
}

.dashTitle {
  position: relative;
  letter-spacing: 1.5px;
  font-size: 13px;

  &::before,
  &::after {
    content: "";
    width: 20%;
    height: 1px;
    background: $light;
    position: absolute;
    left: 0;
    top: 50%;

    @include sm-down {
      width: 20%;
    }
  }

  &::after {
    right: 0;
    left: auto;
  }
}

.tab-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    min-width: 130px;
    height: 45px;
    padding: 6px;
    border-radius: 0px;
    border: 1px solid #B6B6B6;
    // border: 1px solid $light;
    color:  #838383;
    background: transparent;

    &.selected {
      background: transparent;
      color: $black;
      border: 1px solid $black;

    }

    @include sm-down {
      min-width: 100px;
    }
  }
}

.dropdown-toggle {
  width: 100%;

  &::after {
    position: absolute;
    right: 12px;
    opacity: 0;
  }

  .flag-icon {
    border-radius: 50%;
    overflow: hidden;
    margin-left: 3px;
    min-width: 15px;
    width: 15px;
    height: 15px;
    object-fit: cover;
  }

  .dropdown {
    cursor: pointer;
  }
}

.dropdown-menu {
  width: 220px;
  max-height: 270px;
  overflow-y: auto;
  padding: 0;
  background-color: $tertiary;
  border: 2px solid #8080801c;

  @include sm-down {
    max-height: 210px;
  }

  li {
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
      border: 2px solid $white;
      overflow: hidden;
      margin-right: 10px;
      min-width: 25px;
      height: 25px;
      width: 25px;
      overflow: hidden;
      object-fit: cover;
    }

    span {
      color: $white;
    }
  }
}
